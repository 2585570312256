import React, { useEffect, useState } from "react"

import { DEBUG, NODE_SERVER } from '../config'
import packageJson from '../../package.json'

let domains = []
let status = {}
let statusText = {}
let responseTime = {}
let daysRemaining = {}

const Domains = () => {
    const [domainsStatus, setDomainsStatus] = useState({})

    // Update timer state
    const [timer, setTimer] = useState(30)

    useEffect(() => {
        document.title = "TRC Status"
        clearCache()
        DEBUG.FUNCTON === true && console.log(`rendered domains.js`)
        fetchDomains()
        // eslint-disable-next-line
    }, [])

    // Update useEffect to start timer
    useEffect(() => {
        let timerInterval = setInterval(() => {
            if (timer > 1) {
                setTimer(timer => timer - 1)
            }
            else {
                clearInterval(timerInterval)
                fetchDomains()
                setTimer(30)
            }
        }, 1000)
        return () => clearInterval(timerInterval)
        // eslint-disable-next-line
    }, [timer])

    async function fetchDomains() {
        DEBUG.FUNCTON === true && console.log(`fetchDomains()`)
        let URLs = await fetch(`${NODE_SERVER}/api/getRoutes`)
        domains = await URLs.json()
        DEBUG.VERBOSE === true && console.log('URLs: ')
        DEBUG.VERBOSE === true && console.log(domains)
        fetchStatus()
    }

    async function fetchStatus() {
        DEBUG.FUNCTION === true && console.log(`fetchStatus()`)

        // Set the timer for 30 seconds
        setTimeout(() => {
            let loadingStatus = false
            let unresolvedDomainsList = []
            // Check the domainsStatus object to see if any of them has the "Loading..." responseTime value
            domains.forEach((domain) => {
                if (responseTime[domain] === "Loading...") {
                    loadingStatus = true
                    DEBUG.FLAG === true && console.log(`${domain} has not resolved`)
                    unresolvedDomainsList.push(domain)
                }
            })

            if (loadingStatus) {
                // Update status of incomplete requests
                unresolvedDomainsList.forEach((domain) => {
                    DEBUG.FLAG === true && console.log(`These Domains have not resolved:`)
                    DEBUG.FLAG === true && console.log(unresolvedDomainsList)
                    status = { ...status, [domain]: "Still waiting for response..." }
                    setDomainsStatus(status)
                })
            } else {
                // Evaluate the request has completed
            }

        }, 10000)
        domains.forEach(async (domain) => {
            // Set the status to 'loading' while fetching
            DEBUG.API === true && console.log(`fetching ${domain} status`)
            status = { ...status, [domain]: "Loading..." }
            responseTime = { ...responseTime, [domain]: "Loading..." }
            daysRemaining = { ...daysRemaining, [domain]: "Loading..." }

            let request = await fetch(`${NODE_SERVER}/api/validateRoutes/${domain}`)

            let data = await request.json()

            DEBUG.FLAG === true && console.log('fetching status for: ', domain)
            DEBUG.VERBOSE === true && console.log(`data[0].status:`)
            DEBUG.FLAG === true && console.log(data[0].status)
            DEBUG.VERBOSE === true && console.log(`data[0].statusText:`)
            DEBUG.FLAG === true && console.log(data[0].statusText)

            status = { ...status, [data[0].domain]: data[0].status }
            statusText = { ...statusText, [data[0].domain]: data[0].statusText }
            daysRemaining = { ...daysRemaining, [data[0].domain]: data[0].daysRemaining }
            responseTime = { ...responseTime, [data[0].domain]: data[0].responseTime }
            //}
            DEBUG.VERBOSE === true && console.log(status)
            DEBUG.VERBOSE === true && console.log(responseTime)
            setDomainsStatus(status)
        })
    }

    function clearCache() {
        let version = localStorage.getItem('version')
        if (version !== packageJson.version) {
            if ('caches' in window) {
                caches.keys().then((names) => {
                    DEBUG.RESULT === true && console.log(`Cached Version Detected: ${version}\nClearing Cache.`)
                    // Delete all the cache files
                    names.forEach(name => {
                        caches.delete(name)
                    })
                })

                // Makes sure the page reloads. Changes are only visible after you refresh.
                window.location.reload(true)
            }

            localStorage.clear()
            localStorage.setItem('version', packageJson.version)
        }
        DEBUG.RESULT === true && console.log(`Cached Version Not Detected, Not Clearing Cache.`)
    }

    const getStatusCircle = (domain, status, responseTime, daysRemaining) => {
        DEBUG.VERBOSE === true && console.log(`evaluating: ${domain}`)
        DEBUG.VERBOSE === true && console.log(`status: ${status}`)
        DEBUG.VERBOSE === true && console.log(`responseTime: ${responseTime}`)
        DEBUG.VERBOSE === true && console.log(`daysRemaining: ${daysRemaining}`)
        if (status === "Loading...") {
            return <div className="status-circle gray">
                <div className="status-circle-inner"></div>
            </div>
        }
        else if (status !== 200) {
            return <div className="status-circle red">
                <div className="status-circle-inner"></div>
            </div>
        }
        else if (responseTime >= 10 || daysRemaining <= 5) {
            return <div className="status-circle red">
                <div className="status-circle-inner"></div>
            </div>
        }
        else if (responseTime >= 2 || daysRemaining <= 15) {
            return <div className="status-circle yellow">
                <div className="status-circle-inner"></div>
            </div>
        }
        else if (status === 200 && responseTime < 2 && daysRemaining > 15) {
            return <div className="status-circle green">
                <div className="status-circle-inner"></div>
            </div>
        }
    }

    return (
        <div>
            <h1>Domains</h1>
            <span className="comment">Next Refresh: {timer}</span>
            <br />
            <br />
            <table>
                <thead>
                    <tr>
                        <th>Status</th>
                        <th>Domain</th>
                        <th>Status</th>
                        <th>Time</th>
                        <th>SSL Expiration</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(domainsStatus).map((domain, index) => (
                        <tr key={index}>
                            <td>
                                {getStatusCircle(domain, domainsStatus[domain], responseTime[domain], daysRemaining[domain])}
                            </td>
                            <td>{domain}</td>
                            <td>{statusText[domain] === undefined ? "" : statusText[domain] + ": "} {domainsStatus[domain]}</td>
                            <td style={responseTime[domain] > 10 ? { color: 'red' } : (responseTime[domain] > 2 ? { color: 'orange' } : { color: 'black' })}>{isNaN(responseTime[domain]) ? responseTime[domain] : responseTime[domain] + "s"}</td>
                            <td style={daysRemaining[domain] < 15 ? { color: 'red' } : { color: 'black' }}>{isNaN(daysRemaining[domain]) ? daysRemaining[domain] : daysRemaining[domain] + " days"}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default Domains