let DEBUG;

process.env.REACT_APP_DEBUG === 'true' ? (

    DEBUG = {
        FUNCTION: true,
        API: true,
        VERBOSE: true,
        RESULT: true,
        FLAG: true,
        JSON: true,
        PERFORMANCE: true
    }

) : (
    DEBUG = {
        FUNCTION: true,
        API: false,
        VERBOSE: false,
        RESULT: true,
        FLAG: false,
        JSON: false,
        PERFORMANCE: true
    }
)

module.exports = {
    NODE_SERVER: process.env.REACT_APP_NODE_SERVER,
    PASSWORD: process.env.REACT_APP_PASSWORD,
    HOST: process.env.HOST,
    PORT: process.env.PORT,
    DEBUG
}