import React, { useEffect, useState } from "react";
import './Login.css'

import { DEBUG, PASSWORD } from "../config";

const Login = (props) => {
  const {q} = props;
  const [password, setPassword] = useState(q ? q : "");

  useEffect(() => {
    q && setPassword(password) 
    q && handleSubmit(q)
  }, [])

  const handleSubmit = event => {
    const { onSubmit } = props;
    DEBUG.FUNCTION === true && console.log(`handleSubmit()`)
    // check if the provided password matches the one stored in the application
    if (password === PASSWORD) {
      DEBUG.FLAG === true && console.log(`passwords are equal!`)
      // if password is correct, call the onSubmit function
      onSubmit();
    }
  };

  // handle changes to the form fields
  const handleChange = event => {
    DEBUG.FUNCTION === true && console.log(`handleChange()`)
    const { name, value } = event.target;
    setPassword(value);
  };

  return (
    <form onSubmit={handleSubmit} className="login-container">
      <div className="login-form">
        <input
          type="password"
          name="q"
          placeholder="Password"
          value={password}
          onChange={handleChange}
          className="password-input"
        />
      </div>
    </form>
  );
}

export default Login;