import logo from './Quotes.png';
import './App.css';
import Domains from './Status/Domains';
import './Status/Domains.css'
import Login from './Other/Login';
import React from 'react';

import { DEBUG } from './config';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: false
    };
  }

  // onSubmit handler for the Login component
  handleLoginSubmit = () => {
    DEBUG.FUNCTION === true && console.log(`APP: handleLoginSubmit called`)
    this.setState({ isAuthenticated: true });
    DEBUG.FLAG === true && console.log(`isAuthenticated: ${this.state.isAuthenticated}`)
  };

  render() {
    const { isAuthenticated } = this.state;
    const urlParams = new URLSearchParams(window.location.search);
    const q = urlParams.get('q');

    //console.log(`PASSWORD: ${PASSWORD}`)
    //console.log(`NODE_SERVER: ${NODE_SERVER}`)
    DEBUG.FLAG === true && console.log(`isAuthenticated: ${isAuthenticated}`)

    let body;
    if (!isAuthenticated) {
      DEBUG.FLAG === true && console.log(`user not authed`)
      // if user is not authenticated, render the Login component
      body = <Login onSubmit={this.handleLoginSubmit} q={q} />;
    } else {
      DEBUG.FLAG === true && console.log(`user is authed!`)
      body = <Domains />
    }

    return (
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p className="Title">
            <code>Status Page</code>
          </p>
        </header>
        <div className="Status-section">
          {body}
        </div>
      </div>
    );
  }
}

export default App;